const actionStage = {
  NOT_STARTED: 'NOT_STARTED',
  STARTING: 'STARTING',
  SCHEDULED: 'SCHEDULED',
  IN_PROGRESS: 'IN_PROGRESS',
  READY_TO_COMPLETE: 'READY_TO_COMPLETE',
  COMPLETING: 'COMPLETING',
  COMPLETED: 'COMPLETED',
};

export default actionStage;